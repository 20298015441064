/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
// import { useStoreState } from '../../store/store.hooks'
import CountryFlag from './CountryFlag'
import { withStyles } from '@material-ui/core/styles'

const supportedLanguages = [
  {
    locales: ['hu', 'hu-hu'],
    targetLocale: 'hu',
    text: 'Magyar',
    icon: 'hu',
  },
  {
    locales: ['en', 'en-us', 'en-gb'],
    targetLocale: 'en',
    text: 'English',
    icon: 'en',
  },
  {
    locales: ['ua', 'uk-ua', 'ru', 'ru-ru'],
    targetLocale: 'ua',
    text: 'українська',
    icon: 'ua',
  },
]

const styles = theme => ({
  button: {
    color: 'white',
  },
  icon:{
    marginRight: '0.4vw',
  },
})

const LanguageSelector = ({classes}) => {
  // const classes = useStyles()
  const { i18n } = useTranslation()
  const [anchor, setAnchor] = useState(null)
  
  const { language: browserLanguage } = i18n
  let selectedLanguage 

  for (const supportedLanguage of supportedLanguages) {
    for (const supportedLocale of supportedLanguage.locales) {
      if (supportedLocale.toLowerCase() === browserLanguage.toLowerCase()) {
        selectedLanguage = supportedLanguage
      }
    }
  }

  if (!selectedLanguage) {
    console.warn('Unsupported language has been specified:', browserLanguage)
  } else {
    console.info('Language set to:', selectedLanguage, 'based on browser language:', browserLanguage)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  const changeLanguage = lng => {    
    i18n.changeLanguage(lng.targetLocale)
    // Persist the language selection
    localStorage.setItem('i18nextLng', lng.targetLocale)
    closeMenu()
    // if (oldLng !== lng.targetLocale) {
    //   locateToUrl(lng)
    // }
  }

  const handleClick = event => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    closeMenu()
  }

  return (
    <>

      <Button
        className={classes.button}
        disableElevation
        variant="text"
        onClick={handleClick}
        startIcon={<CountryFlag countryCode={selectedLanguage.icon} />}
      >
        <ListItemIcon className={classes.icon}>
          <CountryFlag countryCode={selectedLanguage.icon} />
        </ListItemIcon>
        {selectedLanguage.text}
      </Button>

      <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={handleClose}>
        {supportedLanguages.map(l => (
          <MenuItem key={l.targetLocale} onClick={() => changeLanguage(l)}>
            <ListItemIcon>
              <CountryFlag countryCode={l.icon} state="listItem" />
            </ListItemIcon>
            <Typography variant="inherit">{l.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

LanguageSelector.displayName = 'LanguageSelector'

LanguageSelector.propTypes = {}

LanguageSelector.defaultProps = {}

LanguageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LanguageSelector) 
export { LanguageSelector }
